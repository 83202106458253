import React, { useEffect, useState } from 'react';

import { Typography, Button } from '@mui/material'; 
import TDataColumnsTable from '../../components/TDataColumnsTable';

import { fetchSpreadsheetData } from '../../modules/GoogleSpreadsheet/function/fetchSpreadsheetData';
import { getAllRotarians, getRotarianByEmail, createRotarian, updateRotarian, deleteRotarian } from '../../api_sequelize/api_rotarisns_ridb';
import {getAllOldRotarians} from '../../api_sequelize/api_rotarisns_old';
import {getAllAuth, createUser} from '../../api_sequelize/api_authApi';

const SHEET_ID = '1-X-3ZtgDiG5psTEooU584nctfmgg6RhUKCUjp3L15ss';

const dataColumns = [
    { field: 'Email Address', headerName: 'Email Address' },
    { field: 'Full Name', headerName: 'Full Name' },
    { field: 'Club ID', headerName: 'Club ID' },
    { field: 'Member City', headerName: 'Member City' },
];

const dataColumns2 = [
    { field: 'email', headerName: 'Email Address' },
    { field: 'fullname', headerName: 'Full Name' },
    { field: 'club_id', headerName: 'Club ID' },
    { field: 'city', headerName: 'Member City' },
];

const authDataColumns = [
    { headerName: 'num', type: 'increment' },
    { field: 'email', headerName: 'email' },
    { field: 'password', headerName: 'password' },
    { field: 'isVerified', headerName: 'isVerified' },
    { field: 'status', headerName: 'status' },
];
const aoldDataColumns = [
    { headerName: 'increment', type: 'increment' },
    { field: 'membername', headerName: 'membername' },
    { field: 'email', headerName: 'email' },
    { field: 'password', headerName: 'password' },
    { field: 'hashpwd', headerName: 'hashpwd' },
    { field: 'rotaryID', headerName: 'rotaryID' },
];

// Додаємо колонку для синхронізованої таблиці з полем статусу
const syncedColumns = [
    ...dataColumns,
    { field: 'Status', headerName: 'Status' }
];

const AdminSynchronizeRiDb = () => {
    const [googleData, setGoogleData] = useState(null);
    const [dbData, setDbData] = useState(null);
    const [syncedData, setSyncedData] = useState(null);
    const [emailUser,setEmailUser] = useState(null);

    const [authData, setAuthData] = useState(null);
    const [authOldData, setAuthOldData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // //Отримуємо дані з Google Sheet
                // const googleData = await fetchSpreadsheetData(SHEET_ID, "Members_in_a_Club_Detail!A1:AK1896");
                // setGoogleData(googleData);

                // // Отримуємо дані з бази даних
                // const dbData = await getAllRotarians();
                // setDbData(dbData);

                // // Синхронізуємо дані
                // const syncResult = synchronizeData(googleData, dbData);
                // setSyncedData(syncResult);

                const auth = await getAllAuth();
                setAuthData(auth);
                const aold = await getAllOldRotarians();
                //setAuthOldData(aold.slice(51, 52));//for tykogm2@gmail.com
                setAuthOldData(aold);

                // const du = await getRotarianByEmail('tykogm22@gmail.com');
                // setEmailUser(du);

            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, []);

    // Функція для синхронізації даних з додаванням статусу
    const synchronizeData = (googleData, dbData) => {
        const googleDataMap = new Map(googleData.map(item => [item['Email Address'], item]));
        const dbDataMap = new Map(dbData.map(item => [item.email, item]));

        const result = [];

        // Перевіряємо дані з Google і порівнюємо з базою даних
        googleData.forEach(googleItem => {
            const email = googleItem['Email Address'];
            const dbItem = dbDataMap.get(email);

            if (dbItem) {
                // Якщо запис є і в Google, і в базі даних
                if (googleItem['Full Name'] === dbItem.fullname &&
                    googleItem['Club ID'] === dbItem.club_id &&
                    googleItem['Member City'] === dbItem.city) {
                    result.push({ ...googleItem, Status: 'ідентично' });
                } else {
                    result.push({ ...googleItem, Status: 'змінена' });
                }
            } else {
                // Якщо запис є тільки в Google
                result.push({ ...googleItem, Status: 'нова' });
            }
        });

        // Перевіряємо дані з бази даних, яких немає в Google
        dbData.forEach(dbItem => {
            const email = dbItem.email;
            if (!googleDataMap.has(email)) {
                result.push({
                    'Email Address': dbItem.email,
                    'Full Name': dbItem.fullname,
                    'Club ID': dbItem.club_id,
                    'Member City': dbItem.city,
                    Status: 'відсутня'
                });
            }
        });

        return result;
    };

    const updateDbData = async () => {
        if (!googleData || !dbData) return;

        const dbEmailsMap = new Map(dbData.map((item) => [item.email, item]));

        for (const googleRow of googleData) {
            const googleEmail = googleRow['Email Address'];
            const googleFullName = googleRow['Full Name'];
            const googleClubId = googleRow['Club ID'];
            const googleCity = googleRow['Member City'];

            const dbRotarian = dbEmailsMap.get(googleEmail);

            if (!dbRotarian) {
                // Створюємо новий запис
                await createRotarian({
                    email: googleEmail,
                    fullname: googleFullName,
                    club_id: googleClubId,
                    city: googleCity,
                });
                console.log(`Created new rotarian: ${googleEmail}`);
            } else if (
                dbRotarian.fullname !== googleFullName ||
                dbRotarian.club_id !== googleClubId ||
                dbRotarian.city !== googleCity
            ) {
                // Оновлюємо існуючий запис
                await updateRotarian(dbRotarian.id, {
                    fullname: googleFullName,
                    club_id: googleClubId,
                    city: googleCity,
                });
                console.log(`Updated rotarian: ${googleEmail}`);
            }

            dbEmailsMap.delete(googleEmail);
        }

        for (const [email, rotarian] of dbEmailsMap) {
            await deleteRotarian(rotarian.id);
            console.log(`Deleted rotarian: ${email}`);
        }

        // Після оновлення, знову синхронізуємо дані для відображення
        synchronizeData(googleData, dbData);
    };

    const updateDbData2 = async () => {
        // Оновлення даних у authData з використанням даних з authOldData
        const createOperations = authOldData.map(async (oldData) => {
            const updatedData = {
                email: oldData.email,
                password: oldData.hashpwd,  // Використання хешу як пароля
                isVerified: true,
                status: 'active'
            };
    
            // Перевірка наявності існуючого запису
            const existingAuth = authData.find(auth => auth.email === oldData.email);
            if (!existingAuth) {
                //Якщо запису не існує, реєструємо нового користувача
                return createUser(updatedData)
                    .then(response => console.log(`Registered new user: ${updatedData.email}`, response))
                    .catch(err => console.error(`Error registering user: ${updatedData.email}`, err));
            }
            return null;
        });
    
        // Виконуємо всі асинхронні операції створення
        await Promise.all(createOperations);
    
        // Перезавантаження даних після оновлення
        const updatedAuthData = await getAllAuth();
        setAuthData(updatedAuthData);
    };
    

    return (
        <>emailUser={emailUser ? JSON.stringify(emailUser) : 'null'}
            <Typography variant="h6">Admin Synchronize RiDb</Typography>
            <Button variant="contained" color="primary" onClick={updateDbData}>
                Синхронізувати
            </Button>
            <Button variant="contained" color="secondary" onClick={updateDbData2}>
                Синхронізувати2
            </Button>

            {/* Таблиця з Google Data */}
            {/* <TDataColumnsTable data={googleData} userColumns={dataColumns} /><br/><br/> */}

            {/* Таблиця з даними бази даних */}
            {/* <TDataColumnsTable data={dbData} userColumns={dataColumns2} /><br/><br/> */}

            {/* Синхронізована таблиця з додатковою колонкою "Status" */}
            {/* <TDataColumnsTable data={syncedData} userColumns={syncedColumns} /> */}

            
            <TDataColumnsTable data={authData} userColumns={authDataColumns} /><br/><br/>
            <TDataColumnsTable data={authOldData} userColumns={aoldDataColumns} /><br/><br/>
        </>
    );
};

export default AdminSynchronizeRiDb;
