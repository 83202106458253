import React, { useState } from 'react';

const SpeechTranscriber = () => {
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  let socket;
  let mediaRecorder;

  const startRecording = () => {
    console.log('test: Start recording clicked');
    setIsListening(true);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        socket = new WebSocket('ws://localhost:3001/audio-stream');
        console.log('test: Opening WebSocket connection to ws://localhost:3001/audio-stream');

        socket.onopen = () => {
          console.log('test: WebSocket connection established');
        };

        socket.onerror = (err) => {
          setError('WebSocket error');
          console.error('test: WebSocket error', err);
        };

        mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
        mediaRecorder.start(100); // Передає аудіо кожні 100 мс

        mediaRecorder.ondataavailable = (event) => {
          console.log('test: Data available from mediaRecorder');
          if (socket && socket.readyState === WebSocket.OPEN && event.data.size > 0) {
            console.log('test: Sending audio data to WebSocket');
            socket.send(event.data); // Надсилаємо аудіо дані на сервер через WebSocket
          }
        };

        mediaRecorder.onstop = () => {
          console.log('test: mediaRecorder stopped');
          if (socket && socket.readyState === WebSocket.OPEN) {
            console.log('test: Sending final data and closing WebSocket');
            socket.close();
          }
        };

        mediaRecorder.onerror = (error) => {
          console.error('test: MediaRecorder error:', error);
        };
      })
      .catch((err) => {
        setError('Error accessing microphone');
        console.error('test: Error accessing microphone', err);
      });
  };

  const stopRecording = () => {
    console.log('test: Stop recording clicked');
    setIsListening(false);
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      console.log('test: Stopping mediaRecorder');
      mediaRecorder.stop(); // Зупиняє запис аудіо
    }
    if (socket && socket.readyState === WebSocket.OPEN) {
      console.log('test: WebSocket still open, will close after final data');
    }
  };

  return (
    <div>
      <h2>Speech Transcriber</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={isListening ? stopRecording : startRecording}>
        {isListening ? 'Stop' : 'Start'}
      </button>
    </div>
  );
};

export default SpeechTranscriber;
