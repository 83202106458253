import React from 'react';
import { Container, Paper, Box, Typography } from '@mui/material';
import ResetPasswordForm from '../components/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <Container component="main" maxWidth="xs">
    <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box mb={2}>
        <Typography variant="h4" component="h1" align="center">
            Скинути пароль
        </Typography>
        </Box>
        <ResetPasswordForm />
        </Paper>
    </Container>
  );
};

export default ResetPasswordPage;
