import React, { useState, useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AuthMenu from '../components/AuthMenu';
import LoginForm from '../components/LoginForm';
import PrivatePage from '../components/PrivatePage';
import RegisterForm from '../components/RegisterForm';

const HomePrivatePage = () => {

  return (
    <PrivatePage>
        <Container maxWidth="md">
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to the Private Home Page
          </Typography>
          <Typography variant="body1">
            This page is only accessible to authenticated users. You have successfully logged in!
          </Typography>
        </Container>
    </PrivatePage>
  );
};

export default HomePrivatePage;
