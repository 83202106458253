// src/modules/auth/frontend/services/authApi.js

import axios from 'axios';

const API_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; // Замініть на URL вашого API

// Функція для входу користувача
export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { email, password });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Помилка при вході');
  }
};

// Функція для реєстрації користувача
export const registerUser = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/register`, { email, password });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Помилка при реєстрації');
  }
};

// Функція для перевірки токену
export const verifyToken = async (token) => {
  try {
    const response = await axios.post(`${API_URL}/verify`, { token });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Помилка при перевірці токену');
  }
};

export const verifyEmail = async (token) => {
    try {
      const response = await axios.post(`${API_URL}/verify-email`, { token });
      return response;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Помилка при верифікації електронної пошти');
    }
};

export const sendPasswordResetEmail = async (email) => {
    try {
        const response = await axios.post(`${API_URL}/forgot-password`, { email });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const resetPassword = async (token, password) => {
    try {
        const response = await axios.post(`${API_URL}/reset-password`, { token, password });
        return response.data;
    } catch (error) {
        throw error;
    }
};