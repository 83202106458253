import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllRotarians = async () => {
    console.log('======== getAllRotarians');
  try {
    const response = await api.get('/rotarians');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch rotarians:', error);
    throw error;
  }
};

export const getRotarianById = async (id) => {
  try {
    const response = await api.get(`/rotarian/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch rotarian with id ${id}:`, error);
    throw error;
  }
};

export const getRotarianByEmail = async (email) => {
    try {
      const response = await api.get(`/rotarian_byemail`, {
        params: { email } // Передаємо email як параметр запиту
      });
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch rotarian with email ${email}:`, error);
      throw error;
    }
  };
  
export const createRotarian = async (rotarianData) => {
  try {
    const response = await api.post('/rotarian', rotarianData);
    return response.data;
  } catch (error) {
    console.error('Failed to create rotarian:', error);
    throw error;
  }
};

export const updateRotarian = async (id, rotarianData) => {
  try {
    const response = await api.put(`/rotarian/${id}`, rotarianData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update rotarian with id ${id}:`, error);
    throw error;
  }
};

export const deleteRotarian = async (id) => {
  try {
    await api.delete(`/rotarian/${id}`);
  } catch (error) {
    console.error(`Failed to delete rotarian with id ${id}:`, error);
    throw error;
  }
};
