import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { Container, TextField, Button, IconButton, InputAdornment, Typography, Grid, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { registerUser } from '../services/authApi'; // Імпорт функції для реєстрації
import CloseIcon from '@mui/icons-material/Close'; 
import {tykologAuth} from '../../../../modules/Logs/LogSystem';

// Валідаційна схема для форми
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Некоректний формат емейлу')
        .required('Емейл обов’язковий'),
    password: Yup.string()
        .min(6, 'Пароль має містити не менше 6 символів')
        .required('Пароль обов’язковий')
});

const RegisterForm = ({onClose, auth, toLoginForm}) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [data, setData] = useState(null);

    const formik = useFormik({
        initialValues: {
        email: '',
        password: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
        try {
            const { email, password } = values;
            console.log('onSubmit email='+email);
            const response = await registerUser(email, password);
            console.log(response.data);

            // setData(response.data);
            // if (response.data.inWhiteList) {
            // navigate('/login');
            // } else {
            setErrorMessage('Реєстрацію розпочато. Для завершення реєстрації перейдіть за посиланням в листі, що висланий вже на вказану вами пошту.');
            // }
            tykologAuth('handleRegisterSuccess', { email, data: response.data });
        } catch (error) {
            setErrorMessage(error.message);
        }
        }
    });

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    return (
        <Container maxWidth="xs">
            {/* data={JSON.stringify(data)} */}

            {auth && <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={onClose}>
                <CloseIcon />
                </IconButton>
            </Box>}

        <Box textAlign="center" mb={2}>
            <img
            src="https://7fc65ea596c38701.cdn.express/secure/e94Cs1RC4TMxpHDtWHoQLg/clubsitecreator/D2232/Logo/1707237910091-833961273.png"
            alt="Logo"
            style={{ width: '150px', height: 'auto' }}
            />
        </Box>
        <Typography variant="h5" component="h1" gutterBottom align="center">
            Реєстрація
        </Typography>
        {errorMessage && (
            <Typography color="error" align="center" mb={2}>
            {errorMessage}
            </Typography>
        )}
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                fullWidth
                id="email"
                name="email"
                label="Емейл"
                variant="outlined"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                fullWidth
                id="password"
                name="password"
                label="Пароль"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    )
                }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button color="primary" variant="contained" fullWidth type="submit">
                Зареєструватися
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2" align="center">
                Вже є акаунт? <Button onClick={toLoginForm} >
                Увійти
                </Button>
                </Typography>
            </Grid>
            </Grid>
        </form>
        </Container>
    );
};

export default RegisterForm;
