// src/modules/auth/frontend/components/VerifyEmail.js

import React from 'react';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { verifyEmail } from '../services/authApi'; // Функція для верифікації

const VerifyEmail = () => {
  const { token } = useParams(); // Отримання токена з URL
  const [status, setStatus] = React.useState('loading'); // Статус запиту
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    const verifyToken = async () => {
      try {
        await verifyEmail(token); // Виклик функції для верифікації
        setStatus('success');
        setMessage('Ваша електронна пошта була успішно підтверджена!');
      } catch (error) {
        setStatus('error');
        setMessage('Помилка верифікації. Будь ласка, спробуйте ще раз.');
      }
    };

    verifyToken();
  }, [token]);

  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mt={4}>
        {status === 'loading' && <CircularProgress />}
        {status === 'success' && (
          <Typography variant="h5" color="primary">
            {message}
          </Typography>
        )}
        {status === 'error' && (
          <Typography variant="h5" color="error">
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default VerifyEmail;
