// src/modules/auth/frontend/pages/VerifyEmailPage.js

import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import VerifyEmail from '../components/VerifyEmail'; // Імпорт компонента VerifyEmail

const VerifyEmailPage = () => {
  return (
    <Container maxWidth="xs">
      <Box textAlign="center" mt={4}>
        <Typography variant="h4" gutterBottom>
          Підтвердження Електронної Пошти
        </Typography>
        <VerifyEmail />
      </Box>
    </Container>
  );
};

export default VerifyEmailPage;
