import React from 'react';
import { Typography, Container } from '@mui/material';

const HomePublicPage = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Welcome to the Public Home Page
      </Typography>
      <Typography variant="body1">
        This page is accessible to everyone without the need for authentication. Feel free to explore the content.
      </Typography>
    </Container>
  );
};

export default HomePublicPage;
