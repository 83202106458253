import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllOldRotarians = async () => {
    console.log('======== getAllRotarians');
  try {
    const response = await api.get('/getalloldauth');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch rotarians:', error);
    throw error;
  }
};
