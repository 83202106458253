import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';


const LandingTable = ({ data, columns, onRowClicked, style }) => {
    
    const linkify = (text) => {
      const urlRegex = /(\b(http|https):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
      const parts = text && text.split(urlRegex);

      return parts && parts.map((part, index) => {
          if (part.match(urlRegex)) {
              return <a href={part} target="_blank" rel="noopener noreferrer" key={index}>лінк</a>;
          } else {
              // Перевірка на непотрібні частини URL (http, https)
              if(part !== "http" && part !== "https") {
                  return part;
              }
          }
      });
    };

    return (
        <TableContainer component={style==='transparent' ? 'div' : Paper}>
        
        {//columns={columns && JSON.stringify(columns)
        }

        {data && columns &&
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell 
                                    key={column.field}
                                    style={{ 
                                        color: style === 'transparent' ? 'lightblue' : 'inherit' 
                                    }}
                            >
                                {column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((user, index) => (
                        <TableRow 
                            key={user.id} 
                            onClick={onRowClicked ? () => onRowClicked(index) : null}
                            sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} // Add hover style here
                        >
                            {columns.map((column) => {
                                switch (column.type) {
                                    case 'multilineedit':
                                        return (
                                            <TableCell key={column.field}>
                                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                                    {user[column.field]}
                                                </Typography>
                                            </TableCell>
                                        );
                                    case 'img':
                                        return <TableCell key={column.field}><img src={user[column.field]} height='70px' /></TableCell>;
                                    default:
                                        return <TableCell key={column.field}><Typography style={{ whiteSpace: 'pre-line', color: style === 'transparent' ? 'lightblue' : 'inherit' }}>
                                            {linkify(user[column.field])}
                                          </Typography></TableCell>;
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
}
        </TableContainer>
                        
    );
};

export default LandingTable;
