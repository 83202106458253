import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container } from '@mui/material';
import PrivatePage from '../components/PrivatePage';

const MyRotaryUa = () => {
    const { idclub } = useParams();

    return (
        <PrivatePage>
            <Container maxWidth="md">
            <Typography variant="h4" component="h1" gutterBottom>
                Welcome to the Private Home Page of club {idclub}
            </Typography>
            <Typography variant="body1">
                This page is only accessible to authenticated users. You have successfully logged in!
            </Typography>
            </Container>
        </PrivatePage>
    );
};

export default MyRotaryUa;
