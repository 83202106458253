
import React, { useEffect, useState, useContext, useRef  } from "react";
import { useParams } from 'react-router-dom';
import { LOCALES } from '../../../../i18n/locales';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography, Container, Link } from '@mui/material';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@mui/material';
import { MdEmail } from 'react-icons/md';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import LcButtonFB from '../LcButtonFB';
import LcButtonYoutube from '../LcButtonYoutube';
import LcButtonPdf from '../LcButtonPdf';
import { fetchUser } from '../../rotariansBD/api/api_users';
import LandingMessage from '../../../../components/LandingMessage';
import IMG_EMPTY_USER from '../../../../assets/img/clubscreator/tamplate-overlay_small.jpg';

function SubcardItemComponent({ card, user_drole, index, tags, handleRowClick, currentLocale, ispublic, isemailpublic }) {

    const [user, setUser] = useState([]);

    useEffect(() => {
        console.log('=== useEffect fetchUser id='+card.rotarianId);

        const fetchData = async (id) => {
            try {
                const fetchedUser = await fetchUser(id);
                if (fetchedUser )
                    setUser(fetchedUser[0]);
            } catch (error) {
                console.error(error);
            }
        };

        if (card && card.rotarianId) {
            console.log('=== fetchUser id='+card.rotarianId);
            fetchData(card.rotarianId);
        }
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
          .then(() => {
            alert('Зміст скопійовано в буфер обміну');
          })
          .catch((error) => {
            console.error('Помилка при копіюванні в буфер обміну:', error);
          });
    };

return (
    <>
        {user &&
            <Card sx={{ maxWidth: 360, minWidth: 200 }} key={index} 
                    //</>onClick={() => handleRowClick(index)}
                    >
                <CardActionArea>
                  { card.img1 ?
                    <CardMedia
                        component="img"
                        sx={{ maxWidth: 400 }}
                        image={card.img1}
                        alt="Без фото"
                    /> 
                    :
                    <CardMedia
                        component="img"
                        sx={{ maxWidth: 400 }}
                        image={user.avatar_url ? user.avatar_url : IMG_EMPTY_USER}
                        alt="Без фото"
                    />
                  }
                  { card.img2 && 
                      <CardMedia
                      component="img"
                      sx={{ maxWidth: 400 }}
                      image={card.img2}
                      alt="Без фото"
                    />
                  }
                  <CardContent>
                    <Typography gutterBottom variant="h4" component="div" color='#019fcb' 
                        //onClick={() => handleRowClick(index)}
                    >
                    {user.first_name_ukr} {user.last_name_ukr}
                    {card.name2}
                    </Typography>

                    <Typography gutterBottom variant="h6" component="div">
                    {user.rtype && user.rtype==="District2232" ?
                    <>Дистрикт: Україна</> :
                    
                    <>
                    {(user.club_name || user.club_name_ukr) && 
                    <>
                    {currentLocale===LOCALES.UKRAINIAN ? 
                        <>Клуб: {user.club_type==="RAC" ? <>РАК</> : user.club_type==="PRC" ? <>ПРК</> : user.club_type==="ReC" ? <>РеК</> : <>РК</>} {user.club_name_ukr}</> 
                        :
                        <>Club: { user.club_type==="RAC" ? <>RAC</> : user.club_type==="PRC" ? <>PRC</> : user.club_type==="ReC" ? <>ReC</> : <>RC</>} {user.club_name_ukr}</> 
                    }
                    </>}</>
                    
                    }
                    </Typography>

                    <Typography variant="subtitle1" style={{ whiteSpace: 'pre-line' }} >
                    {user_drole ? 
                    <>
                        <LandingMessage id={user_drole} noCollapse={false} maxLenght={300} />
                        {/* <Typography variant="h6" >Роль:</Typography> {user_drole} */}
                    </>
                    :
                    <>
                        <Typography variant="h6" >Роль:</Typography> {user.	club_position}
                    </>}
                    </Typography>


                    {user.genlink && <Link href={card.genlink} target="_blank" rel="noopener noreferrer">
                    Go to project page
                  </Link>}
                    {user.docs && user.docs.map((item) => (
                        <LcButtonPdf url={item} size='default' />
                    ))}
                    
                    {/*<Typography variant="body2" color="text.secondary">
                        {user.content && 
                            <lu>
                            <LandingContentItems content={user.content} />
                            </lu>
                        }
                        {user.other_positions && 
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                            {user.other_positions}
                            </Typography>
                        }
                    </Typography>*/}
                    
                    {!ispublic &&
                    <>
                    <br/>
                    <Button onClick={() => handleRowClick(index)} >Детальніше...</Button>
                    </>
                    }
                  </CardContent>
                </CardActionArea>
                {(!ispublic || isemailpublic) &&
                <CardActions>
                    <Typography gutterBottom variant="h7" >
                    {(!ispublic) && <>Contacts: {user.primarycontact}</>}
                    </Typography>

                    {(!ispublic || isemailpublic) && user.email && <Tooltip title={'Email'}>
                    <IconButton onClick={() => copyToClipboard(user.email)} aria-label="Facebook">
                        <MdEmail color="blue" size='1.5em' />
                    </IconButton>
                    </Tooltip>}

                    {(!ispublic) && user.phone && <Tooltip title={'Phone'}>
                    <IconButton onClick={() => copyToClipboard(user.phone)} aria-label="Facebook">
                        <PhoneIcon color="primary" size='1.5em' />
                    </IconButton>
                    </Tooltip>
                    }
                    
                    {(!ispublic) && user.youtube && (
                        <LcButtonYoutube url={user.youtube} size='default' />
                    )}
                    {(!ispublic) && user.fb && (
                        <LcButtonFB url={user.fb} size='default' />
                    )}

                    
                </CardActions>
                }
            </Card>
        }
        </>
    );
}


export default SubcardItemComponent;