import React, { useState, useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AuthMenu from './AuthMenu';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import {tykologAuth} from '../../../../modules/Logs/LogSystem';

const PrivatePage = ({children}) => {
    const { auth, setAuth } = useAuth();
    const [activeForm, setActiveForm] = useState(null); // 'login', 'register', or null

    useEffect(() => {
        setActiveForm(auth ? null : 'login');
    }, [auth]);

    // Логін, реєстрація, розлогінення
    const handleLogin = () => {
        setActiveForm('login');
    };

    const handleRegister = () => {
        setActiveForm('register');
    };

    const handleForgot = () => {
        setActiveForm('forgotpwd');
    };

    const handleLogout = () => {
        setAuth(null);
        tykologAuth('handleLogout');
    };

    const handleCancel = () => {
        setActiveForm(null);
    };

    const handleLoginSuccess = (email) => {
        setActiveForm(null);
        tykologAuth('handleLoginSuccess', {email});
    };

    // Рендер форми залежно від активної
    const renderForm = () => {
        if (activeForm === 'login') {
            return <LoginForm onClose={handleCancel} onSuccess={handleLoginSuccess} auth={auth} toRegisterForm={handleRegister} toForgotForm={handleForgot} />;
        }
        if (activeForm === 'register') {
            return <RegisterForm onClose={handleCancel} auth={auth} toLoginForm={handleLogin}/>;
        }
        if (activeForm === 'forgotpwd') {
            return <ForgotPasswordForm onClose={handleCancel} />;
        }
        return null;
    };

    return (
    <div>
        {/* Використання компонента AuthMenu */}
        <AuthMenu onLogin={handleLogin} onRegister={handleRegister} onLogout={handleLogout} />

        {/* Відображення форми логіну або реєстрації */}
        <Container maxWidth="sm">{renderForm()}</Container>

        {/* Основний контент сторінки */}
        {!activeForm && auth && (
        <>{children}</>
        )}

        {/* Якщо користувач не авторизований або показуємо логін */}
        {(!auth || activeForm === 'login') && !activeForm && (
        <Container maxWidth="sm">
            <LoginForm onClose={handleCancel} onSuccess={handleLoginSuccess} auth={auth} toRegisterForm={handleRegister} />
        </Container>
        )}
    </div>
    );
};

export default PrivatePage;
