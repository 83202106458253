import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getAllLogs = async () => {
    console.log('======== getAllLogs');
  try {
    const response = await api.get('/logs');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch logs:', error);
    throw error;
  }
};

export const getLogById = async (id) => {
  try {
    const response = await api.get(`/log/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch log with id ${id}:`, error);
    throw error;
  }
};

export const createLog = async (logData) => {
  try {
    const response = await api.post('/log', logData);
    return response.data;
  } catch (error) {
    console.error('Failed to create log:', error);
    throw error;
  }
};

export const updateLog = async (id, logData) => {
  try {
    const response = await api.put(`/log/${id}`, logData);
    return response.data;
  } catch (error) {
    console.error(`Failed to update log with id ${id}:`, error);
    throw error;
  }
};

export const deleteLog = async (id) => {
  try {
    await api.delete(`/log/${id}`);
  } catch (error) {
    console.error(`Failed to delete log with id ${id}:`, error);
    throw error;
  }
};
