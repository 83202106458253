import React from 'react';
import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

const TDataColumnsTable = ({ data, userColumns }) => {
  // Рендер одного рядка таблиці
  const renderRow = (rowData, rowIndex) => {
    return (
      <TableRow key={rowData.log_id}>
        {userColumns.map((column) => (
          <TableCell key={column.field}>
            {renderCell(rowData, column, rowIndex)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  // Рендер однієї клітинки таблиці згідно з її типом
const renderCell = (rowData, column, rowIndex) => {
    const cellData = rowData[column.field] || column.default;
    switch (column.type) {
        case 'increment':
            return <span>{rowIndex+1}</span>;
        case 'lineedit':
        case 'multilineedit':
            return <span>{cellData}</span>;
        case 'img':
            return <img src={cellData} alt={column.headerName} style={{ maxWidth: '100%' }} />;
        default:
            return <span>{cellData}</span>;
    }
};

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {userColumns && userColumns.map((column) => (
              <TableCell key={column.field}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
            {data && data.map((rowData, rowIndex) => renderRow(rowData, rowIndex))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TDataColumnsTable;
