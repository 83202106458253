import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Avatar, Menu, MenuItem, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const AuthMenu = ({ onLogin, onRegister, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  // Відкриття меню
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  // Закриття меню
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Private Home Page
        </Typography>

        {/* Аватар для відкриття меню */}
        <IconButton edge="end" color="inherit" onClick={handleMenuOpen}>
          <Avatar>
            <AccountCircleIcon />
          </Avatar>
        </IconButton>

        {/* Спадне меню */}
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{ style: { width: '200px' } }}
        >
          <MenuItem onClick={() => { handleMenuClose(); onRegister(); }}>Реєстрація</MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); onLogin(); }}>Логін</MenuItem>
          <MenuItem onClick={() => { handleMenuClose(); onLogout(); }}>Вийти</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AuthMenu;
