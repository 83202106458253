import {createLog} from '../../api_sequelize/api_logs';

// Функція для генерації унікального device_id
const generateDeviceId = () => {
    return 'device_' + Math.random().toString(36).substr(2, 9); // Генеруємо випадковий рядок
}

// Функція для отримання або створення device_id
const getDeviceId = () => {
    let deviceId = localStorage.getItem('tykorotary_user_id'); // Перевіряємо наявність device_id у LocalStorage
    if (!deviceId) {
        deviceId = generateDeviceId(); // Генеруємо новий device_id, якщо його ще немає
        localStorage.setItem('tykorotary_user_id', deviceId); // Зберігаємо у LocalStorage
    }
    return deviceId;
}

// Основна функція tykologAuth
export const tykologAuth = (msg, advData) => {
    const logData = {
        device_id: getDeviceId(),  // Отримуємо або генеруємо device_id
        msg,
        proj: 'auth',
        func: '',
        status: '',
        type: 'statistics',
        tags: '',
        debug: '',
        advData,
    }

    createLog(logData); // Викликаємо функцію для створення лога з logData
}
