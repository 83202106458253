// src/modules/auth/frontend/services/authApi.js

import axios from 'axios';

const API_BASE_URL = 'https://api-sequelize.rotaryd2232ukraine.org/db'; 

const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
});

export const createUser = async (userData) => {
    try {
        console.log('=============createUser '+JSON.stringify(userData));
      const response = await api.post('/adduseruath', userData);
      return response.data;
    } catch (error) {
      console.error('Failed to create user:', error);
      throw error;
    }
};

export const getAllAuth = async () => {
    console.log('======== getAllRotarians');
    try {
        const response = await api.get('/getallauth');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch rotarians:', error);
        throw error;
    }
};