import React from 'react';
import { Container, Paper, Box, Typography } from '@mui/material';
import LoginForm from '../components/LoginForm';

const LoginPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box mb={2}>
          <Typography variant="h4" component="h1" align="center">
            Вхід до системи
          </Typography>
        </Box>
        <LoginForm />
      </Paper>
    </Container>
  );
};

export default LoginPage;
