import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [loading, setLoading] = useState(true); // Додаємо стан для завантаження

    // Завантажуємо токен з localStorage при ініціалізації
    useEffect(() => {
        const token = localStorage.getItem('tyko-rotary:authToken');
        if (token) {
        console.log('test: Auth token loaded from localStorage');
        setAuth({ token: JSON.parse(token) });
        } else {
        console.log('test: No token found in localStorage');
        }
        setLoading(false); // Завершуємо завантаження
    }, []);

    useEffect(() => {
        if (auth) {
            localStorage.setItem('tyko-rotary:authToken', JSON.stringify(auth.token));
        } else {
            localStorage.removeItem('tyko-rotary:authToken');
        }
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, loading }}>
        {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
