import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from 'react-router-dom';

import { LOCALES } from '../../../i18n/locales';
import {LocalContext} from '../../../tools/LanguageContextProvider';

import { getSiteById, getSiteByUrl } from '../../../api_sequelize/api_sites';
import { getSiteMenusBySiteId } from '../../../api_sequelize/api_sites';
import RcClubPageMain_BD from '../ClubPageComponents/RcClubPageMain_BD';

export const getMenuStructure = (site, menuarr) => {
    const data = site && {
        navbar: {
            title: site.title,
            logoUrl: site.logourl,
            languages: site.languages,
            addlogoborder: true,
            withoutd2232url: true,
            menu: menuarr && menuarr.map((menu) => ({
                title: menu.btnText,
                lref: '/ds'+menu.btnUrl,
            }))
        }
    };
    return data;
}

const TykoSitesPage = () => {
    const {currentLocale, setCurrentLocale} = useContext(LocalContext);
    const [siteData, setSiteData] = useState(null);
    const [siteMenuArr, setSiteMenuArr] = useState(null);
    const [menujsondata, setMenujsondata] = useState(null);
    //const [siteId, setSiteId] = useState(null);
    const { sitepath } = useParams();
    //const siteId = 1;

    useEffect(() => {
        const fetchData = async () => {
          try {
              await loadData(currentLocale);
          } catch (error) {
            console.log("getHomeLanding get list error="+error);
          }
        };
        fetchData();
    }, [])

    const loadData = async(locale) => {
        const site = await getSiteByUrl(sitepath);
        const siteid = site && site[0] && site[0].id;
        if (!siteid) return;

        setSiteData(site && site[0]);
        console.log("siteid="+siteid);
        if (site && siteid) {
            if (site && site[0]?.languages==='ua')
              setCurrentLocale(LOCALES.UKRAINIAN);
            const sMenu = await getSiteMenusBySiteId(siteid);
            const sortedMenu = sMenu.sort((a, b) => a.menuId - b.menuId);
            setSiteMenuArr( sortedMenu );
            console.log("sMenu="+JSON.stringify(sortedMenu));
            console.log("site="+JSON.stringify(site));

            setMenujsondata(getMenuStructure(site && site[0], sortedMenu));
        }
    }

    return (
        <>
        {/* TykoSitesPage<br/>
        sitepath={sitepath}<br/>
        siteData={JSON.stringify(siteData)}<br/>
        siteData.id={siteData ? siteData.id : 'N/A'}<br/><br/>
        siteMenuArr={JSON.stringify(siteMenuArr)}<br/><br/>
        getMenuStructure={JSON.stringify(menujsondata)}<br/> */}
        {siteData && siteMenuArr && 
        <RcClubPageMain_BD 
            menudata={menujsondata} 
            designType={siteData.designtype}
        />}
        
        </>
  );
}

export default TykoSitesPage;